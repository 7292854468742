import React from 'react';
import { graphql } from 'gatsby';
import { List, Main, Layout } from '../components';

// eslint-disable-next-line no-undef
export const categoryQuery = graphql`
  query PostsByCategory($category: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___title] }
      filter: {
        frontmatter: { category: { eq: $category }, active: { eq: true } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            category
          }
        }
      }
    }
  }
`;

const CategoryTemplate = props => {
  const {
    pageContext: { category },
    data: {
      allMarkdownRemark: { edges }
    }
  } = props;

  return (
    <Layout>
      <Main>
        {() => (
          <React.Fragment>
            <h1>{`Categoria de ${category}`}</h1>
            <hr />
            <List edges={edges} />
          </React.Fragment>
        )}
      </Main>
    </Layout>
  );
};

export default CategoryTemplate;
